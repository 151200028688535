import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate, useParams } from 'react-router-dom';
import JobSearch from '../pages/JobSearch';
import SkeletonLoader from '../utils/SkeletonLoader';
import { getApiBaseUrl } from '../utils/Config';

function Jobs() {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams(); // Include setSearchParams
    const what = searchParams.get('what');
    const salaryRange = searchParams.get('salaryrange');
    const salaryType = searchParams.get('salarytype');
    const workType = searchParams.get('worktype');
    const categoryId = searchParams.get('categoryId');
    const suburb = searchParams.get('suburb');
    const state = searchParams.get('state');
    const postcode = searchParams.get('postcode');
    const sponsorship = searchParams.get('sponsorship') === 'true';
    const remote = searchParams.get('remote') === 'true';
    const [currentPage, setCurrentPage] = useState(parseInt(searchParams.get('pageNumber')) || 1);
    const [totalPages, setTotalPages] = useState(0);
    const [jobResults, setJobResults] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const proximity = searchParams.get('proximity') || '50'; // Default proximity to 50km
    const [selectedProximity, setSelectedProximity] = useState(proximity);
    const baseUrl = getApiBaseUrl();
    useEffect(() => {
        // Create dynamic title based on search parameters
        const jobType = what ? what.replace(/-/g, ' ') : 'All';
        const location = suburb ? `${suburb}, ${state || ''}` : state || 'Australia';

        document.title = `${jobType} Jobs in ${location} | TalentUp.com.au`;
    }, [what, suburb, state]);

    useEffect(() => {
        const fetchJobs = async () => {
            setIsLoading(true);
            setError(null);

            try {
                const queryParams = new URLSearchParams({
                    what: what || '',
                    suburb: suburb || '',
                    state: state || '',
                    postcode: postcode || '',
                    salaryRange: salaryRange || '',
                    workType: workType || '',
                    categoryId: categoryId || '',
                    proximity: proximity || '',
                    ...(sponsorship !== null && { isSponsorship: sponsorship }),
                    ...(remote !== null && { remote }),
                    pageNumber: currentPage,
                });

                const response = await fetch(`${baseUrl}/api/JobSearch/JobSearch?${queryParams.toString()}`);

                if (!response.ok) throw new Error('Error fetching jobs');
                const data = await response.json();
                setJobResults(data.result.items || []);
                setTotalPages(data.result.totalPages || 0);
            } catch (error) {
                setError("Whoops, something went wrong. Please try again later.");
            } finally {
                setIsLoading(false);
            }
        };

        fetchJobs();
    }, [searchParams, currentPage]);


    // Function to select a random color class
    const getRandomColorClass = () => {
        const colorClasses = ['purple1', 'purple2', 'bg-purple-500'];
        return colorClasses[Math.floor(Math.random() * colorClasses.length)];
    };
    function getSalaryRangeText(salaryRangeValue) {
        const salaryRanges = {
            1: "Under $30,000",
            2: "$30,000 - $50,000",
            3: "$50,000 - $70,000",
            4: "$70,000 - $90,000",
            5: "$90,000 - $110,000",
            6: "$110,000 - $130,000",
            7: "$130,000 - $150,000",
            8: "$150,000 - $170,000",
            9: "$170,000 - $190,000",
            10: "$190,000 - $210,000",
            11: "$210,000 - $230,000",
            12: "$230,000 - $250,000",
            13: "$250,000 and above",
            14: "Negotiable",
            15: "Competitive",
        };

        return salaryRanges[salaryRangeValue] || "Not specified";
    }

    function getWorkTypeText(workTypeValue) {
        const workTypes = {
            1: "Full-Time",
            2: "Part-Time",
            3: "Casual",
            4: "Contract",
            5: "Temporary",
            6: "Freelance",
            7: "Internship",
            8: "Consultancy",
            9: "Apprenticeship",
        };

        return workTypes[workTypeValue] || "Not specified";
    }

    const proximityOptions = ['5km', '10km', '20km', '50km', '100km'];

    const handlePageChange = (newPageNumber) => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setCurrentPage(newPageNumber);

        // Prepare query parameters
        const queryParams = new URLSearchParams();

        if (what) queryParams.append('what', what);
        if (salaryRange) queryParams.append('salaryrange', salaryRange);
        if (workType) queryParams.append('worktype', workType);
        if (suburb) queryParams.append('suburb', suburb);
        if (categoryId) queryParams.append('categoryId', categoryId);
        if (state) queryParams.append('state', state);
        if (postcode) queryParams.append('postcode', postcode);
        if (proximity) queryParams.append('proximity', proximity);
        if (sponsorship) queryParams.append('sponsorship', sponsorship);
        if (remote) queryParams.append('remote', remote);
        if (newPageNumber) queryParams.append('pageNumber', newPageNumber);

        // Navigate to the updated route
        navigate(`/jobs?${queryParams.toString()}`);
    };

    const handleProximityChange = (newProximity) => {
        setSelectedProximity(newProximity);
        setSearchParams((prev) => {
            const newParams = new URLSearchParams(prev);
            newParams.set('proximity', newProximity);
            return newParams;
        });
    };



    const calculateTimeElapsed = (startDateString) => {
        const startDate = new Date(startDateString);
        const now = new Date();
        const timeDifference = now - startDate;

        const minutes = Math.floor(timeDifference / 60000);
        const hours = Math.floor(timeDifference / 3600000);
        const days = Math.floor(timeDifference / (3600000 * 24));

        if (minutes < 60) return `${minutes} min ago`;
        else if (hours < 24) return `${hours} hours ago`;
        return `${days} days ago`;
    };

    const renderPagination = () => {
        let pages = [];
        for (let i = 1; i <= totalPages; i++) {
            pages.push(
                <li key={i} className={`page-item ${i === currentPage ? 'active' : ''}`}>
                    <a className="page-link" onClick={() => handlePageChange(i)} style={{ cursor: "pointer" }}>
                        {i}
                    </a>
                </li>
            );
        }
        return pages;
    };
    useEffect(() => {
        console.log('Updated searchParams:', searchParams.toString());
    }, [searchParams]);

    return (
        <>
            <JobSearch />

            <div className="background-grey-result">
                <div className="se_muj_as023 mb-5">
                    <div className="top-data-search">
                        <div className="container-fluid">
                            <div className="top-data-search-inner container-xxl">
                                <div className="row align-items-center">
                                    {suburb && state && (
                                        <div className="location-filter">
                                            <p>
                                                All jobs within{' '}
                                                <select
                                                    className="form-select-inline"
                                                    value={selectedProximity}
                                                    onChange={(e) => handleProximityChange(e.target.value)}
                                                    style={{
                                                        display: 'inline',
                                                        width: 'auto',
                                                        fontSize: 'inherit',
                                                        border: 'none',
                                                        backgroundColor: 'transparent',
                                                        padding: '0 5px',
                                                    }}
                                                >
                                                    {proximityOptions.map((option) => (
                                                        <option key={option} value={option.replace('km', '')}>
                                                            {option}
                                                        </option>
                                                    ))}
                                                </select>{' '}
                                                of {suburb}, {state}
                                            </p>
                                        </div>
                                    )}
                                    <div className="col-sm col-12">
                                        <h3>
                                            Search Results for{' '}
                                            <span className="search-results-tag">
                                                {what ? `${what.replace(/-/g, ' ')} Jobs` : 'All Jobs'}
                                            </span>
                                            {suburb && state
                                                ? ` in ${suburb}, ${state}`
                                                : state
                                                    ? ` in ${state}`
                                                    : ''}
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-xxl">
                    <div id="search-results">
                        <div className="search-result_allpk">
                            <div className="row">
                                <div className="col-sm-12 col-md-12">
                                    <div className="gap-3 col-xs-12 col-md-12">
                                        <div className="advert-pa-ou-lay">
                                            {isLoading ? (
                                                <SkeletonLoader type="JobList" />
                                            ) : error ? (
                                                <p>{error}</p>
                                            ) : (
                                                jobResults.map((job) => (
                                                    <article
                                                        key={job.JobPostID}
                                                        id={`${job.BusinessName.replace(/\s+/g, '-').toLowerCase()}-${job.Title.replace(/\s+/g, '-').toLowerCase()}-${job.JobPostID}`}
                                                        className={
                                                            job.IsUrgentlyHiring
                                                                ? 'css-dlxf47 mb-4'
                                                                : 'css-dlxf46 mb-4'
                                                        }
                                                        onClick={() => navigate(`/job/${job.JobPostID}`)}
                                                        style={{ cursor: 'pointer' }}
                                                    >
                                                        <div className="vstack gap-6">
                                                            <div className="card">
                                                                <div className="card-body">
                                                                    <div className="job_post_headerlogo">
                                                                        <div className="d-flex align-items-center">
                                                                            <div
                                                                                className={`icon icon-shape rounded-3 me-auto text-lg text-white d-flex justify-content-center align-items-center ${getRandomColorClass()}`}
                                                                                style={{ width: '40px', height: '40px' }}
                                                                            >
                                                                                {job.BusinessName &&
                                                                                    job.BusinessName.charAt(0).toUpperCase()}
                                                                            </div>
                                                                            <div>
                                                                                {job.IsUrgentlyHiring ? (
                                                                                    <span className="badge text-uppercase bg-soft-primary text-primary rounded-pill">
                                                                                        Featured
                                                                                    </span>
                                                                                ) : (
                                                                                        <time itemProp="datePosted" dateTime={new Date(job.StartDate).toISOString()}>
                                                                                            {calculateTimeElapsed(job.StartDate)}
                                                                                        </time>

                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="job_post_header">
                                                                        <div className="d-flex align-items-center advert-pa-ou-iuj">
                                                                            <h2 className="css-12koetd me-auto">
                                                                                <a
                                                                                    href={`/job/${job.JobPostID}`}
                                                                                    itemProp="url"
                                                                                    onClick={(e) =>
                                                                                        handlePageChange(e, job.JobPostID)
                                                                                    }
                                                                                >
                                                                                    {job.Title}
                                                                                </a>
                                                                            </h2>
                                                                        </div>
                                                                    </div>
                                                                    <div className="job_post_sub_header">
                                                                        <div className="mb-2">
                                                                            <h5>
                                                                                <span itemProp="hiringOrganisation">{job.BusinessName}</span> | <span itemProp="jobLocation">{job.Suburb}, {job.State}{' '} 
                                                                                    ({job.Postcode})  </span>
                                                                            </h5>
                                                                        </div>
                                                                        <div className="mb-2">
                                                                            {job.SalaryRange > 0 && !job.IsSalaryHidden && (
                                                                                <span itemProp="jobSalaryRange" className="badge bg-cust-tag text-cust-d-pur me-2">
                                                                                    <i className="bi bi-coin"></i> {getSalaryRangeText(job.SalaryRange)}
                                                                                </span>
                                                                            )}
                                                                            <span className="badge bg-cust-tag text-cust-d-pur me-2">
                                                                                <i className="bi bi-briefcase"></i> {getWorkTypeText(job.WorkTypeID)}
                                                                            </span>
                                                                            {job.IsSponsorship && (
                                                                                <span className="badge bg-cust-tag text-cust-d-pur me-2">
                                                                                    <i className="bi bi-airplane"></i> Open to Sponsorship
                                                                                </span>
                                                                            )}
                                                                            {job.WorkModelTypeID === 1 && (
                                                                                <span className="badge bg-cust-tag text-cust-d-pur me-2">
                                                                                    <i className="bi bi-house"></i> Remote Possible
                                                                                </span>
                                                                            )}
                                                                        </div>
                                                                        <p itemProp="jobShortDescription" className="MuiTypography-root MuiTypography-body2 css-sns183">
                                                                            {job.ShortDescription}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </article>
                                                ))
                                            )}
                                        </div>
                                    </div>
                                    <nav aria-label="Page navigation example">
                                        <ul className="pagination pagination-sm pagination-spaced gap-2 justify-content-end p-8">
                                            <li
                                                className={`page-item ${currentPage <= 1 ? 'disabled' : ''
                                                    }`}
                                            >
                                                <a
                                                    className="page-link"
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() =>
                                                        handlePageChange(Math.max(1, currentPage - 1))
                                                    }
                                                >
                                                    <i className="bi bi-chevron-left"></i>
                                                </a>
                                            </li>
                                            {renderPagination()}
                                            <li
                                                className={`page-item ${currentPage >= totalPages ? 'disabled' : ''
                                                    }`}
                                            >
                                                <a
                                                    className="page-link"
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() =>
                                                        handlePageChange(
                                                            Math.min(totalPages, currentPage + 1)
                                                        )
                                                    }
                                                >
                                                    <i className="bi bi-chevron-right"></i>
                                                </a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Jobs;
